.checkbox-wrapper input[type="checkbox"] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    width: 16px;
    height: 16px;
    border-radius: 4px;
    /*margin-right: 0.5em;*/
    border: 1.5px solid #3072E3;
    outline: none;
    /*cursor: pointer;*/
}
.checkbox-wrapper input:checked {
    background-color: #3072E3;
    position: relative;
}

.checkbox-wrapper input:checked::before {
    content: "\2713";
    font-size: 16px;
    color: #fff;
    position: absolute;
    /*right: 1px;*/
    top: -3px;
    left:2px;
}
.containerRadio{
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.containerRadio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.checkmarkRadio {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
}
/* On mouse-over, add a grey background color */
.containerRadio:hover input ~ .checkmarkRadio {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.containerRadio input:checked ~ .checkmarkRadio {
    background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmarkRadio:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerRadio input:checked ~ .checkmarkRadio:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.containerRadio .checkmarkRadio:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}