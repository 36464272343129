.popover .arrow {
    display: none;
}
.popover{
    width:max-content;
}
.tooltip .arrow {
    display: none;
}
.patients-table-physician {
    padding: 10px 20px;
    font-size: 17px;
}
.tableToolTip{
    background-color: #FFFFFF;
    border: 1px solid lightgray;
    border-radius: 0.7rem;
    padding:1rem;
    margin-top: 0.7rem;
}
.gantToolTip{
    /*background-color: #FFFFFF;*/
    /*border: 1px solid D3D3D3FF;*/
    border-radius: 0.7rem;
    padding:1rem;
    margin-top: 0.7rem;
    position: absolute;
    top: 16% !important;
    left:40% !important;
    z-index: 5;
}

.versionToolTip{
    /*background-color: #FFFFFF;*/
    /*border: 1px solid lightgray;*/
    border-radius: 0.7rem;
    position: absolute;

    margin-bottom: 0.7rem;
}
.versionToolTip > div.tooltip-inner{
    background-color: #FFFFFF;
    box-shadow: 0px 5px 12px #a4a0a0;
    opacity: 1 !important;
    border: 1px solid #D0D0D0;
    border-radius: 1rem !important;
    width: auto !important;
    max-width: fit-content;
    padding: 0.25rem;
}

.formToolTip{
    background-color: #FFFFFF;
    border: 1px solid lightgray;
    border-radius: 0.7rem;
    padding:1rem;
    margin-top: 0.7rem;
}
.tableToolTip:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: lightgray;

}
.gantToolTip:before {
    content: '';
    display: block;
    position: absolute;
    left: 121%;
    bottom: 57%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: lightgray;

}
.formToolTip:before {
    content: '';
    display: block;
    position: absolute;
    left: 46%;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: lightgray;

}
.gantToolTip:after {
    content: '';
    display: block;
    position: absolute;
    left: 121%;
    bottom: 57%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-bottom-color: white;

}

.tableToolTip:after {
    content: '';
    display: block;
    position: absolute;
    left: 50.2%;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-bottom-color: white;

}
.formToolTip:after {
    content: '';
    display: block;
    position: absolute;
    left: 46%;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-bottom-color: white;

}