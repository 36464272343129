.modal-dialog{

    min-height: 250px !important;
    min-width: 350px!important;
    max-width: 579px;
    /* width: 579px; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 999;
    display: flex;
    align-items: center;
    /*box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;*/

}
/*.modelDialogConfirm{*/
/*    min-height: 250px !important;*/
/*    min-width: 350px!important;*/
/*    max-width: 579px;*/
/*    !* width: 579px; *!*/
/*    !*position: absolute;*!*/
/*    !*bottom: 41%;*!*/
/*    !*left: 39%;*!*/
/*    text-align: center;*/
/*    z-index: 999;*/
/*    display: flex;*/
/*    align-items: center; */
/*}*/
.modalHeader{
    height:55px;
}
/*.modal-content{*/
/*    min-width:370px !important;*/
/*    margin: 25px;*/
/*}*/
.app-modal-title{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 1.375rem;
    /*line-height: 24px;*/
    color: #1D355C;
    margin: 10px;

}
.app-modal-content{
    margin-top: 1rem;
    margin-bottom: 1rem;
    color:#444444;
    font-size: 1.2rem;
    /*max-width: 15rem;*/
}

.modal-content{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    /*border: 1px solid rgba(0,0,0,.2);*/
    border-radius: 1.3rem;
    outline: 0;
    text-align: center;
    max-width: 35rem;
}
.modal-date-content{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 228px;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0;
    text-align: center;
    /*left:120%;*/
    /* left: 142%; */
    /* left: 105%; */
}
.button-confirm{
    margin-top: 10px;
    align-items: center;
    padding: 6px 12px 6px 8px;
    gap: 4px;

    width: 123px;
    height: 32px;

    /* Primary */

    background: #17597C;
    border-radius: 8px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */


    /* Secondary Light */

    color: #FFFFFF;


}
.modal-close{
    background-color: white!important;
    color: #4da6ff!important;
}
.modal-close:hover{
    box-shadow: 1px 1px;
}
.alpha-modal-content{
    position: relative;
    /* top: 20px; */
    text-align: center;
    background-color: #ffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.modal-confirm{
    position: relative !important;
    left: 0% !important;
    top: 0px !important;
    height: 35px !important;
    width: 120px !important;
    font-size: 16px !important;
}
.modal-close{
    position: relative;
    right:40px;
}
.errorMessage{
    height: auto;
    min-height: 73px !important;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 29px;
}
