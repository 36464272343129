@tailwind base;
@tailwind components;
@tailwind utilities;

/*@layer base {*/
/*  html {*/
/*    font-family: Roboto,Roboto,Roboto-bold system-ui, sans-serif;*/
/*  }*/
/*}*/
html, body, #root {
  height: 100%;
  width: 100%;
  font-family: Roboto !important;
  background-color: #ffffff;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
