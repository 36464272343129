
.App {
    text-align: center;
    font-family: Roboto;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}
@media (min-width: 1400px) and (max-width: 1600px) {
    .dateSelect{
        width:17rem !important;
    }
}
.Mui-focused {
    outline: 0 !important;
    border: none !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    font-family: Roboto !important;
}
input:focus {
    outline: none !important;
}
/*.inputClass{*/
/*    font: inherit!important;*/
/*}*/

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.verification-code-container input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.verification-code-container input[type=number] {
    -moz-appearance: textfield;
}

.error-label-verification {
    font-size: 18px !important;
    font-weight: 400 !important;
    max-width: calc(100% - 20px);
    font-family: Roboto;
    font-stretch: normal !important;
    font-style: normal !important;
    /*line-height: 1.71!important;*/
    letter-spacing: 0.35px !important;
    position: relative !important;
    margin-bottom: 0px !important;
    display: flex;
    align-items: center;
}

.password-requirements {
    font-size: 0.875rem;
    margin-top: 0.625rem;
    justify-content: space-between;
}


.password-requirements ul {
    margin: 0;
    padding: 0 0 0 1.25rem;
}

.password-requirements li {
    list-style: disc;
    margin-bottom: 0.313rem;
    color:#75797A;
    font-family: Roboto;
}

.auth-label {
    margin-top: 0.875rem;

}
button:disabled{
    opacity: 0.5;
}

/*.flag-dropdown {*/
/*    width: 50px !important;*/
/*    background-color: #4da6ff;*/
/*    border:1px solid gray;*/
/*    margin-right: 10px !important;*/
/*    border-radius:10px !important;*/
/*}*/
/*.react-tel-input .form-control{*/

/*}*/
.loginPagesClass{

}
.search-input-date .react-datepicker{
    background-color: #F2F5FA !important;
    width: 346px !important;
    height: 339px !important;
}
.alpha-date-time .react-datepicker{
    background-color: #F2F5FA !important;
    width: 346px !important;
    height: 339px !important;
}

.search-input-date .react-datepicker__month-container{
    width: 100% !important;
    height: 100% !important;
}
 .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
    width: 2.6rem !important;
    line-height: 2rem !important;
}
 .react-datepicker__header{
    background-color: transparent !important;
    border-bottom: none !important;

}
.search-input-date .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
    height: 3.5rem !important;
    font-size: 1.125rem !important;
    line-height: 1.313rem !important;
    font-weight: 400 !important;
    margin-left: 1.5rem !important;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #444444 !important;
}
.alpha-date-time .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
    height: 3.5rem !important;
    font-size: 1.125rem !important;
    line-height: 1.313rem !important;
    font-weight: 400 !important;
    /* identical to box height */

    display: flex;
    align-items: center;
    justify-content: center;

    color: #444444 !important;
}
.react-datepicker__navigation{
    top: 8% !important;
}
.search-input-date .react-datepicker__navigation--previous {
    left: 80% !important;
}

.react-datepicker__close-icon::after{
   background-color: transparent !important;
    color: #75797A !important;
}
.before-today {
    background-color: #eee;
    color: #bbb;
}
/*.react-datepicker__time-container::-webkit-scrollbar{*/
/*    width: 2px !important;*/
/*}*/
.plans-container::-webkit-scrollbar{
    width: 4px !important;
    color: #1D355C !important;

}
.radio-input{
    display: none;
}
/*.css-b62m3t-container{*/
/*    z-index: 7!important;*/
/*}*/
.select-menu{
    z-index: 100!important;
}
.bs-tooltip-bottom{
    z-index: 9!important;
}

.tooltip > div.tooltip-inner {
    /* background-color: #FFFFFF; */
    /* box-shadow: 0px 5px 12px #a4a0a0; */
    opacity: 1 !important;
    /* border: 1px solid #D0D0D0; */
    border-radius: 1rem !important;
    width: auto !important;
    max-width: -moz-fit-content;
    max-width: fit-content;
}
.tooltip.bs-tooltip-right .tooltip-arrow::before {
    border-right-color: #D0D0D0 !important;
    background-color:#FFFFFF !important;
}
.tooltip.bs-tooltip-left .tooltip-arrow::before {
    border-left-color: #D0D0D0 !important;
    background-color:#FFFFFF !important;
}
.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
    border-bottom-color: #FFFFFF !important;
}
.tooltip.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: #D0D0D0 !important;
    background-color:#FFFFFF !important;
}
.toolTip-arrow:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-bottom: solid 14px white;
    border-top: solid 14px transparent;
    border-left: solid 14px transparent;
    border-right: solid 14px transparent;
    top: -13px;
    left: -14px;
}
.toolTip-arrow {
    position: absolute;
    top: -28px;
    left: 33%;
    width: 0;
    height: 0;
    border-bottom: solid 15px #D0D0D0;
    border-top: solid 15px transparent;
    border-left: solid 15px transparent;
    border-right: solid 15px transparent;
}
.SelectAlphaIcon .css-1nmdiq5-menu{
    margin-left: -2.1rem !important;
    z-index: 999 !important;
}
.radio-custom-style{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 1.563rem;
    height: 1.563rem;
    border: 2px solid #D7DDDF;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
}
.radio-custom-style:checked{
    background-color:#17597C;
}

.rbc-month-view{
    z-index: 0!important;
}
.select-repeat .css-1nmdiq5-menu{
    z-index: 8 !important;
}
.svgStripes{
    background-color: #9bc1dd;
    background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%231a80e6' fill-opacity='0.22' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
}
::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb {
    background: #17597C;
    border-radius: 10px;
}
.css-1u9des2-indicatorSeparator{
    position: relative !important;
    right: 30% !important;
}
.select__clear-indicator{
    position: relative !important;
    right: -17% !important;
}
.css-1n6sfyn-MenuList{
    font-size: 16px !important;
}

